<template>
  <div>
  <b-form @submit.prevent>
    <b-card no-body id="pdfDom">

        <!-- <b-card-body class="text-center"> -->

        <!-- </b-card-body> -->


          <b-card-body class="invoice-padding pb-0">
            <b-card-title class="text-center">Form Payment Verifications</b-card-title>

            <div class="row">

              <!-- Header: Left Content -->
              <div class="col-auto col-md-6 col-12 text-right">
                <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary invoice-logo">
                    {{ master[0].code }}
                  </h3>
                </div>
                <p class="card-text mb-0">
                    <span class="font-weight-bolder d-block text-nowrap">
                       {{ (master[0].contact) ? master[0].contact.label : 'Transfer' }}
                    </span>
                </p>
                <p>
                    <small class="font-italic"> <span class="font-weight-bolder"> {{ companyActive.plan_id > 2 ? (master[0].office ? master[0].office.label : master[0].account.account_name) : master[0].account.account_name }}</span>  </small>
                 </p>
              </div>

              <div class="col-auto col-md-6 col-12">
                <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary invoice-logo">
                    <p class="card-text mb-0">
                    <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', (master[0].amount > 0) ? 'text-success' : 'text-danger' ]">
                        {{ formatCurrency(master[0].amount) }}
                    </span>
                </p>
                  </h3>
                </div>
                 <p class="card-text mb-0">
                    <span class="font-weight-bolder d-block text-nowrap">
                        {{ master[0].updated_process }}  By {{ master[0].getUser.user_name }}
                    </span>
                    <small class="font-italic">{{ new Date(master[0].updated_at).toDateString() }}</small>
                 </p>
              </div>

            </div>
          </b-card-body>


        <b-table
              id="refFormTable"
              ref="refFormTable"
              fixed
              no-provider-paging
              no-provider-filtering
              :fields="tableColumns"
              :items="forms.details"
              primary-key="id"
              show-empty
              class="position-relative mt-2"
        >

       <template #table-busy>
          <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
            <div class="mb-1 text">
              <b-spinner class="align-middle"/>
            </div>
            <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
          </div>
        </template>

        <!-- Column: Amount -->
      <template #cell(req_amount)="{ item }">
        <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', (item.req_amount > 0) ? 'text-success' : 'text-danger' ]">
          {{
            formatCurrency(item.req_amount)
          }}
        </span>
      </template>

        </b-table>
    <div class="pb-3 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-between">
      <!-- untuk biar aga kebawah ygy -->
    </div>
    <div class="pb-3 pl-2 pr-2 d-flex flex-sm-column flex-md-row justify-content-between">
            <!-- tombol back ygy  -->
            <b-button
              class="mb-sm-1 mb-md-0"
              variant="outline-primary"
              :to="{ name: 'apps-ap-posting-list' }"
            >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
            </b-button>
            <!-- tombol update ygy  -->
            <b-button
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
               {{ $t('globalActions.post') }}
              </b-button>
        </div>
    </b-card>
   </b-form>
 </div>
</template>

<script>
import {
    BCard,
    BCardBody,
    BCardTitle,
    BTable,
    BForm,
    BButton,
    BSpinner
}from 'bootstrap-vue'
import useListTable from '@/comp-functions/useListTable'
import useFormResource from '@/comp-functions/useFormResource'
import { ref, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { format,parse } from 'date-fns'
import { formatCurrency } from '@/utils/formatter'




export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BTable,
    BForm,
    BButton,
    BSpinner
  },
  setup () {
      const {
        changeToEdit,
        store,
        get,
        paramsId,
        show
      }= useFormResource({ url: 'transaction/purchase/posting-ap', localeContextPath: 'apps.transactionsJournal.singular.transaction', redirectPathName: 'apps-master-data-journal-template' })
    const tableColumns = [
      { key: 'doc_type', label: 'Doc Type' },
      { key: 'doc_number', label: 'Doc Number' },
      { key: 'contact', label: 'Contact' },
      { key: 'date', label: 'Date' },
      { key: 'due_date', label: 'Due Date' },
      { key: 'office', label: 'office' },
      { key: 'req_amount', label: 'Req Amount' },
      { key: 'remark', label: 'Descriptions' },
      { key: 'by', label: 'By' }
    ]
    const {
        itemLists,
        searchQuery
    } = useListTable({ url: false })
    const form = ref({
      id: ''
    })
    const forms = ref({
      details: [
        {
         no: 'no',
         doc_type: 'Doc Type',
         doc_number: 'Doc Number',
         contact: 'Contact',
         date: 'date',
         due_date: 'Due Date',
         office: 'office',
         req_amount: 'Req Amount',
         by: 'By'
        }
      ]
    })
    const { toast ,$post } = useHttp()
    const getDataPreview = async () => {
        const data = await show()
        forms.value.details = data
        let number = 1
        forms.value.details = forms.value.details.map(field => {
        const dates = parse(field.date, 'dd/MM/yyyy', new Date())
        const dates_due = parse(field.due_date, 'dd/MM/yyyy', new Date())
        const detail = {}
        detail.no = number
        detail.doc_type = field.doc_type
        detail.doc_number = field.doc_number
        detail.contact = field.contact
        detail.date = format(dates,  'd MMMM yyyy')
        detail.due_date = format(dates_due,  'd MMMM yyyy')
        detail.office = field.office
        detail.req_amount = field.req_amount
        detail.by = field.by
        number++
        return detail

      })

    }

    let master = ref()
    const getForm = async () => {
        master.value = await get({ url: `transaction/purchasevoucher/invoice/${paramsId}`})
    }



    onMounted(() => {
      getForm()
        form.value.id = paramsId
        getDataPreview()
    })


    return {
        master,
        tableColumns,
        itemLists,
        searchQuery,
        changeToEdit,
        store,
        form,
        $post,
        paramsId,
        toast,
        forms,
        show,
        formatCurrency
    }
  },
    methods: {
        async handleSubmit () {
        const form = new FormData()

          let id = this.form.id
          form.append('id',id)

          console.log(id)
            this.$post({
              data:form,
              url: `/transaction/purchase/posting-ap`
            }).then((res) => callbackSuccess(res))

          const callbackSuccess = () => {
          this.toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'BellIcon',
              variant: 'success',
              text: `Successfully Created new Invoice!.`
            }
          })
          router.replace({ name: `apps-ap-posting-list` })

        }

        }
    },
    computed: {
    companyActive () {
      return this.$store.state.user.currentCompany
    }
  }
}
</script>
